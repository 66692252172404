
.el-input,
.el-select,
.el-select--small,
.el-cascader,
.el-autocomplete,
.el-select--small,
.el-date-picker {
  width: 100%;
}
.submit-selecet-option {
  max-width: 300px;
  font-size: 13px;
  line-height: 17px;
  white-space: break-spaces;
}
