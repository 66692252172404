
.my-transfer-panel {
  border: 1px solid #dcdfe6;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 10px;
  width: 150px;
  height: 200px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .my-transfer-item {
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    &:hover {
      color: #3498fc;
    }
    span {
      display: inline-block;
      position: relative;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      background-color: #fff;
      z-index: 1;

      &::before {
        box-sizing: content-box;
        content: "";
        border: 1px solid #fff;
        border-left: 0;
        border-top: 0;
        height: 7px;
        left: 4px;
        position: absolute;
        top: 1px;
        width: 3px;
        transform-origin: center;
        transform: rotate(45deg) scaleY(1);
      }
    }
  }
  .my-transfer-item-select {
    color: #409eff;
    &:hover {
      color: #0a335c;
    }
    span {
      &::after {
        transform: rotate(45deg) scaleY(1);
      }
      background: #409eff;
    }
  }
}
.transfer-wrap {
  display: flex;
  .transfer-button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
}
.my-transfer-panel-title {
  text-align: center;
}
