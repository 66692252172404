
.common-table-container {
  .el-table {
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          font-size: 14px;
        }
      }
    }
    .el-table__row {
      font-size: 13px;
      color: #39363a;
    }
  }
}
