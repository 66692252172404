
.manage {
  height: 90%;

  .manage-header {
    display: flex;
    justify-content: space-around; //靠两边
    align-items: center; //垂直状态居中
  }

  .common-tabel {
    position: relative;
    height: calc(100% - 62px);

    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
}
