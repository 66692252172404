.el-form-item__label {
    color: rgba(43, 39, 44, 1)!important;
  }
.el-tag{
    
    &.success{
        border: none;
        background: #cdf185;
        color: #606266;
    }
    &.warning{
        background: #d5d9d9;
        color: #606266;
        border: none;
    }
    &.fail{

    }
}
.el-table{
    .el-table__header-wrapper{
        .el-table__header{
            .has-gutter{
                tr{
                    th{
                        border: 1px solid #eee;
                        background: #f5f7fa;
                        .cell{
                            color:#302a2a;
                        }
                    }
                  
                }
              }
        }
    }
}
.el-statistic{
    .head{
        .title{
            font-size: 18px;
            color:#302a2a;
            font-weight: 500;
        }
    }
    .con{
        .number{
            font-size: 15px;
            color: #303234;
            font-weight: 500;
        }
    }
}
.el-dialog{
    margin: 0!important;
    position: absolute!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: scroll;

// 滚动条的样式,宽高分别对应横竖滚动条的尺寸
&::-webkit-scrollbar {
    width: 3px;
  }
  // 滚动条里面默认的小方块,自定义样式
  &::-webkit-scrollbar-thumb {
    background: #8798AF;
    border-radius: 2px;
  }

// 滚动条里面的轨道
  &::-webkit-scrollbar-track {
    background: transparent;
}

}